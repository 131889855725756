/*!
Copyright (C) 2020 Evolved Binary

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as
published by the Free Software Foundation, either version 3 of the
License, or (at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/
@font-face {
  font-family: petal;
  src: url("petal.820287e8.eot");
  src: url("petal.820287e8.eot#iefix") format("embedded-opentype"), url("petal.be11dca2.ttf") format("truetype"), url("petal.263c0435.woff") format("woff"), url("petal.a46b96ed.svg#petal") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ic-"], [class*=" ic-"] {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: petal !important;
}

.ic-bug:before {
  content: "";
}

.ic-image:before {
  content: "";
}

.ic-open:before {
  content: "";
}

.ic-undo:before {
  content: "";
}

.ic-redo:before {
  content: "";
}

.ic-olist:before {
  content: "";
}

.ic-ulist:before {
  content: "";
}

.ic-bold:before {
  content: "";
}

.ic-underline:before {
  content: "";
}

.ic-italic:before {
  content: "";
}

.ic-superscript:before {
  content: "";
}

.ic-subscript:before {
  content: "";
}

.ic-github:before {
  content: "";
}

.ic-download:before {
  content: "";
}
/*# sourceMappingURL=index.59f964bb.css.map */
