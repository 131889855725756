/*!
Copyright (C) 2020 Evolved Binary

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as
published by the Free Software Foundation, either version 3 of the
License, or (at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

@font-face {
  font-family: 'petal';
  src:  url('fonts/petal.eot?7etgz5');
  src:  url('fonts/petal.eot?7etgz5#iefix') format('embedded-opentype'),
    url('fonts/petal.ttf?7etgz5') format('truetype'),
    url('fonts/petal.woff?7etgz5') format('woff'),
    url('fonts/petal.svg?7etgz5#petal') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ic-"], [class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'petal' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-bug:before {
  content: "\e999";
}
.ic-image:before {
  content: "\e90d";
}
.ic-open:before {
  content: "\e930";
}
.ic-undo:before {
  content: "\e967";
}
.ic-redo:before {
  content: "\e968";
}
.ic-olist:before {
  content: "\e9b9";
}
.ic-ulist:before {
  content: "\e9bb";
}
.ic-bold:before {
  content: "\ea62";
}
.ic-underline:before {
  content: "\ea63";
}
.ic-italic:before {
  content: "\ea64";
}
.ic-superscript:before {
  content: "\ea69";
}
.ic-subscript:before {
  content: "\ea6a";
}
.ic-github:before {
  content: "\eab0";
}
.ic-download:before {
  content: "\ea36";
}